import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import * as styles from "../styles/privacy.module.css";

export default function PrivacyPolicy() {
  return (
    <div className={styles.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Leaderboard Golf App</title>
      </Helmet>
      <div className={styles.content}>
        <h1>Privacy Policy</h1>
        <p>
          Leaderboard is an iOS app that provides real-time scoring and
          leaderboards for any golf tournament, whether it be a golf trip with
          friends, a charity golf tournament, or a member-guest. Sign up with
          just a username, password, and first and last name. That's it. When
          you log in, the leaderboard system will use your first and last name
          to identify you to other players. We put a lot of focus on keeping the
          data we require from you minimal, and for that data we do collect, we
          strive to keep it private and secure.
        </p>
        <p>
          In the sections below, we outline the ways in which we collect and use
          data from the app.
        </p>
        <h2>Data Collected</h2>
        <h3>User data</h3>
        <p>
          When you create an account on Leaderboard, you are asked to enter your
          full name. This data is simply used to identify you to other
          Leaderboard users during tournaments and makes it easy for your
          friends tell who you are and how to find you on the app.
        </p>
        <h3>Scoring data</h3>
        <p>
          When you play in a golf tournament and use Leaderboard to track your
          scoring, Leaderboard will save your scores for those tournaments so
          that you can come back later to view your results and where you placed
          in the standings/leaderboard. Your score in a tournament will only be
          visible to those other users who joined that tournament from the app.
          Once you post scores in a tournament, that score data becomes
          permanent and anyone with access to that tournament can view your
          score.
        </p>
        <h2>Our Right to Change this Policy</h2>
        <p>
          We reserve the right to make modifications to this policy at any time
          as the app grows and adds new features. You will always be able to
          access this policy from the App Store to view its contents.
        </p>
        <h2>Deleting Your Account</h2>
        <p>
          To deactivate your account, visit the website at{" "}
          <Link to="/support">our support page</Link> and fill out a request to
          deactivate your account. We will promptly work towards deleting your
          account as soon as we receive that request.
        </p>
      </div>
    </div>
  );
}
